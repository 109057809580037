import React, { useState, useEffect } from "react";
import "./Navbar.css"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../../redux/Slices/cartSlice";
import { MdVerified } from 'react-icons/md';
import { AiOutlinePlusSquare, AiOutlineSetting, AiOutlineStar } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { useAuth } from '../../Utils/AuthContext';
import { UpgradePage } from "../../Pages/UpgradePage";
import { HiHome } from "react-icons/hi2";
import { CraftsModal, NewPost } from "../../Pages";
import { EditDp } from "../Login";

const Navbar = ({ search }) => {
    const hcc_id = localStorage.getItem('hcc_id');
    const { isLoggedIn, is24Craft } = useAuth();
    const Navigate = useNavigate();
    const location = useLocation();
    const { cart } = useSelector((state) => state);
    const total = useSelector(selectCartTotal);
    const [LoggedIn, setLoggedIn] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [query, setQuery] = useState(search);
    const handleLoginClose = () => setloginShow(false);
    const [ActiveUrl, setActiveUrl] = useState("")
    const [NoAccess, setNoAccess] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    function handleInputChange(event) {
        setQuery(event.target.value);
    }
    const ActiveData = (activeUrl) => {
        localStorage.setItem("activeurl", activeUrl)
    }
    const GetlocaData = () => {
        const Accounturl = localStorage.getItem("activeurl")
        setActiveUrl(Accounturl)
    }
    function handleSubmit(event) {
        event.preventDefault();
        Navigate(`/product-search?query=${query}`)
    }

    function handleLoginEvent() {
        handleLoginClose(false);
        setLoggedIn(true)
    }
    const [ProfileData, setProfileData] = useState({})
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setLoggedIn(true)
            setProfile()
        }
    }
    const [AddPost, setAddPost] = useState(false)
    useEffect(() => {
        CheckLogin();
        setProfile();
        GetlocaData();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    const MobileLogin = () => {
        Navigate("/account")
    }
    const [UpgradeShow, setUpgradeShow] = useState(false)
    const [ScreenVisbile, setScreenVisbile] = useState("plans")
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const handleprofilpicclose = () => {
        setProfilepic(false)
    }
    const handleClose = () => {
        setshowApply(false)
    }
    const HandleProfilepic = () => {
        if (ProfileData?.profile_pic === null) {
            setProfilepic(true)
        } else if (localStorage.getItem('is_24_craft') === null) {
            setshowApply(true)
        }
        else {
            HandleUpgrade();
        }
    }
    const HandleUpgrade = () => {
        setUpgradeShow(true)
    }
    const handleUpgradeClose = () => {
        setUpgradeShow(false)
        setScreenVisbile("plans")
    }
    const [show, setShow] = useState(false);
    const handleshow = () => {
        setShow(true)
    }
    const handleclose = () => {
        setShow(false)
    }
    const moreActions = (type) => {
        handleclose()
        if (type === "settings") {
            Navigate(`/profile/${hcc_id}`)
        } else if (type === "payment") {
            Navigate("/payment-history")
        } else if (type === "password") {
            Navigate(`/profile/${hcc_id}`)
        } else if (type === "logout") {
            Navigate("/logout")
        }
    }
    const frames_24 = localStorage.getItem('frames_24');
    return (
        <>

            <div className="main-menu">
                <div className="menu-container">
                    <div className="menu-item" onClick={() => Navigate('/')}>
                        <NavLink to="/">
                            <HiHome className="menu-icon" />
                            <p className="sidebar-title">Home</p>
                        </NavLink>
                    </div>
                    <div className="menu-item" onClick={() => Navigate('/auditions')}>
                        <NavLink to="/auditions">
                            {location.pathname === '/auditions' ? (
                                <img src={require("./images/Auditionactive.webp")} className="menu-icon" />
                            ) : (
                                <img src={require("./images/audition.webp")} className="menu-icon" />
                            )}
                            <p className="sidebar-title">Auditions</p>
                        </NavLink>
                    </div>
                    {isLoggedIn &&
                        <>
                            <div className="menu-item" onClick={(e) => { setAddPost(true); }}>
                                <a>
                                    <img src={require("./images/newpost.webp")} className="menu-icon" color="#daa520" />
                                    <p className="sidebar-title">Post</p>
                                </a>
                            </div>
                            {frames_24?.split(',')?.some(role =>
                                ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                            ) && <div className="menu-item" onClick={() => Navigate('/shortlist')}>
                                    <NavLink to="/shortlist">
                                        {location.pathname === '/shortlist' ? (
                                            <img src={require("./images/shortlistactive.webp")} className="menu-icon" />
                                        ) : (
                                            <img src={require("./images/shortlist.webp")} className="menu-icon" />
                                        )}
                                        <p className="sidebar-title">Shortlist</p>
                                    </NavLink>
                                </div>
                            }
                            <div className="menu-item" onClick={HandleProfilepic}>
                                <a>
                                    <img src={require("./images/pro.webp")} className="menu-icon" color="#daa520" />
                                    <p className="sidebar-title">{JSON.parse(localStorage.getItem("is_pro")) ? "Pro Member" : "Upgrade"}</p>
                                </a>
                            </div>
                            <div className="menu-item" onClick={() => Navigate(`/request-verifications`)}>
                                <NavLink to="/request-verifications">
                                    <MdVerified className="menu-icon" color="#019bf9" />
                                    <p className="sidebar-title" style={{ fontWeight: "400" }}>Verified Profile</p>
                                </NavLink>
                            </div>
                            <div className="menu-item" onClick={() => Navigate(`/profile/${hcc_id}`)}>
                                <NavLink to={`/profile/${hcc_id}`}>
                                    <img src={ProfileData && ProfileData.profile_pic ? ProfileData.profile_pic : require("../Post/images/male.jpg")} className="profile-pic-top" alt={`${ProfileData?.first_name} ${ProfileData?.last_name}`} />
                                    {/* <MdOutlineAccountCircle className="menu-icon" /> */}
                                    <p className="sidebar-title">Profile</p>
                                </NavLink>
                            </div>
                            <div className="menu-item" onClick={handleshow}>
                                <a>
                                    <img src={require("./images/more.webp")} className="menu-icon" style={{ height: "22px" }} />
                                    <p className="sidebar-title">More</p>
                                </a>
                            </div>
                        </>
                    }
                    {/* <div className="menu-item" onClick={() => Navigate('/s')}>
                        <NavLink to="/s">
                            <MdOutlineExplore className="menu-icon" />
                            <p className="sidebar-title">Search</p>
                        </NavLink>
                    </div> */}
                </div >
                {UpgradeShow &&
                    <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
                }
                {
                    AddPost &&
                    <NewPost AddPost={AddPost} setAddPost={setAddPost} />
                }
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                    <Modal.Header closeButton>
                        <Modal.Title>Add your profile to get started</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                    </Modal.Body>
                </Modal>
                {showApply &&
                    <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
                }
                <Modal size='xs' aria-labelledby="example-modal-sizes-title-xs" centered show={show} onHide={handleclose} className="more-modal">
                    <Modal.Body style={{ padding: 0 }}>
                        <div className="more-content">
                            <div className="more-item" onClick={() => moreActions("settings")}><AiOutlineSetting size={32} /><p>Settings</p></div>
                            <div className="more-item" onClick={() => moreActions("payment")}><img src={require("./images/payment.png")} /><p>Payment History</p></div>
                            <div className="more-item" onClick={() => moreActions("password")}><img src={require("./images/Password.png")} /><p>Change Password</p></div>
                            <div className="more-item" onClick={() => moreActions("logout")}><img src={require("./images/logouts.png")} /><p>Logout</p></div>
                            <div className="more-item" onClick={() => moreActions("close")}><p>Cancel</p></div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    )
}
export { Navbar };