import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Plans from './Plans'
import { IsProComponent } from './IsProComponent'
import axiosConfig from "../Service/axiosConfig";
import checkProRealtime from '../Utils/checkProRealtime';


const UpgradePage = ({ UpgradeShow, setUpgradeShow, ScreenVisbile, setScreenVisbile, handleUpgradeClose, RowId1 }) => {
    const [BuyNowData, setbuyNowData] = useState({})
    const [plans, setPlans] = useState({});
    const hnadlescreen = (Title, month, month_3, year, discount) => {
        setbuyNowData({ Title: Title, month_3: month_3, year: year, discount: discount, month: month })
        setScreenVisbile(Title)
    }
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const fetchPlans = async () => {
        try {
            const response = await axiosConfig.get('/masters/plans/');
            setPlans(response.data?.results[0]);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    };
    const AudiInterest = () => {
        axiosConfig.post("/accounts/audition-interests/", { audition_requirement: RowId1 }).then(res => {
        }).catch(error => {
            console.log(error);
        });
    }

    const RefreshLogin = async () => {
        try {
            if (localStorage.getItem('is_pro') != "true") {
                const response = await axiosConfig(`/accounts/api/refresh_login/`)
                if (response.data.is_pro) {
                    localStorage.setItem('is_pro', response.data.is_pro ? true : false);
                    alert("looks you are already pro")
                    window.location.reload();
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const checkCondition = async () => {
            await checkProRealtime.checkCondition(); // Alerts and reloads will be handled inside the service
        };
        checkCondition();
        fetchPlans();
        AudiInterest()
    }, []);
    return (
        <>
            <Modal
                size="md"
                show={UpgradeShow}
                onHide={handleUpgradeClose}
                aria-labelledby="example-modal-sizes-title-md"
                className="modal-size-craft upgrade"
                style={{ paddingLeft: "0px" }}
                centered
            // fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title className='ms-auto'>
                        {/* hyderabadcastingclub */}
                        <div className="logo justify-content-center">
                            <img src={require('../Pages/images/hcclogo.webp')} className="logo-image" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="success-icon p-0">
                    {JSON.parse(localStorage.getItem("is_pro")) ? (
                        <IsProComponent />
                    ) : (
                        ScreenVisbile === "plans" ? (
                            <div className='upgrade-auto'>
                                <header className="upgrade-header">
                                    <div>
                                        <h4>Go Pro Today</h4>
                                        <h4>Exclusive Features, Unlimited Auditions!</h4>
                                    </div>
                                    <p className='hide-mobile'>
                                        As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                                    </p>
                                </header>
                                <section className='container hide-for-desktop'>
                                    <h2 className='upgrade-h2'>Why Upgrade?</h2>
                                    <p>
                                        As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                                    </p>
                                </section>
                                <section className="container">
                                    <h2 className='upgrade-h2'>Here’s What You Get</h2>
                                    <ul className="feature-list">
                                        <li>🔓 Unlimited Audition Applications</li>
                                        <li>🔍 Priority in Search Results</li>
                                        <li>🎬 Exclusive Access to Premium Auditions</li>
                                        {/* <li>📧 Send Unlimited Messages</li> */}
                                        <li>🚫 No Ads for a Seamless Experience</li>
                                        <li>🚀 Early Access to New Features</li>
                                    </ul>
                                </section>
                                <section className="cta-section">
                                    <h2>Don’t Miss Out – Upgrade to Pro Now!</h2>
                                    <div onClick={() => hnadlescreen("", plans?.price, "499", "1499", "0")}>
                                        <p className='hide-mobile'>
                                            With Pro, you get the tools and visibility to take your acting career to the next level. Make it count!
                                        </p>
                                        <p><strong style={{ color: "#FFF" }}>₹{(plans?.price / plans?.duration_months).toFixed(0)} / month</strong></p>
                                    </div>
                                </section>
                            </div>
                        ) : (
                            <Plans
                                setScreenVisbile={setScreenVisbile}
                                BuyNowData={BuyNowData}
                                setbuyNowData={setbuyNowData}
                            />
                        )
                    )}

                </Modal.Body>
            </Modal >
        </>
    )
}

export { UpgradePage }
