import React from "react";
import "./Post.css"
import { Shortlist } from "../ShortList/ShortList";


const ActionsDp = ({ item }) => {
    const frames_24 = localStorage.getItem('frames_24');
    return (
        <>
            <dpostiv className="actionsdp-list">
                {/* <a className="btn btn-default d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                    <b>{item.views ? item.views : 0}</b> views
                </a>
                <a className="btn btn-default d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                    <b>{item.total_posts ? item.total_posts : 0}</b> Posts
                </a> */}
                <div className="message-btn">
                    <img src={require("../../Pages/images/messages.png")} width={20} alt="message-icon" />Message
                </div>
                {frames_24?.split(',')?.some(role =>
                    ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                ) &&
                    <Shortlist ShortlistId={item.id} />
                }
            </dpostiv>
            {/* <div className="actionsdp-list row">
                <div className="col-4">
                    <button className="btn btn-default d-flex">
                        <b>{item.views ? item.views : 0}</b> views
                    </button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default d-flex">
                        <b>{item.views ? item.views : 0}</b> Posts
                    </button>
                </div>
                <div className="col-4">
                    <Shortlist ShortlistId={item.id} />
                </div>
            </div> */}
        </>
    )
}
export { ActionsDp };