import React, { useState } from 'react'
import "./chat.css"
import { MdOutlineVideocam, MdOutlineInfo, MdOutlinePhone } from "react-icons/md";
import { BiSearch } from 'react-icons/bi';
import { BsEmojiSmile } from "react-icons/bs";
import { RiImage2Fill } from "react-icons/ri";
import { TiMicrophoneOutline } from "react-icons/ti";
const ChatList = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const chats = [
        { id: 1, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "02", profilePic: 'male.jpg' },
        { id: 2, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 3, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 4, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 5, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 6, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 7, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
        { id: 8, name: 'Dvithik_76', message: 'Good luck!', time: '09:11 PM', unread: "01", profilePic: 'male.jpg' },
    ];
    return (
        <>
            <div className='d-flex'>
                <div className="chat-list">
                    <h4>All Chats</h4>
                    <div className="searchbar">
                        <input type="search" placeholder="Search for people" />
                        <BiSearch size={25} />
                    </div>
                    <div className='chat-tabs'>
                        <div className='chat-type'>All</div>
                        <div>Unread</div>
                        <div>request</div>
                    </div>
                    <div className="chat-items">
                        {chats.map((chat, index) => (
                            <div
                                key={index}
                                className={`chat-item ${selectedChat === chat.id ? 'active' : ''}`}
                                onClick={() => setSelectedChat(chat.id)}
                            >
                                <img
                                    src={require(`../Post/images/${chat.profilePic}`)}
                                    alt="profile-pic"
                                    width="40"
                                    className="profile-pic"
                                />
                                <div className="chat-details">
                                    <h5>{chat.name}</h5>
                                    <p>{chat.message}</p>
                                </div>
                                <div className="chat-meta">
                                    <span className="time">{chat.time}</span>
                                    {chat.unread > 0 && <span className="unread-count">{chat.unread}</span>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
        </>
    )
}

export default ChatList