// paymentFunctions.js
import axiosconfig from "../Service/axiosConfig"




// Get existing orders by profile_id
export const getExistingOrder = async (profileId) => {
  console.log(profileId)
  const profileData = JSON.parse(localStorage.getItem("profile_data"));
  try {
    const response = await axiosconfig.get(`/accounts/orders/?profile=${profileId ? profileId : profileData?.profile_id}`
      // , {
      //   params: { profile: profileId },
      // }
    );
    return response.data?.results;
  } catch (error) {
    console.error('Error fetching existing order:', error);
    throw error;
  }
};

// Handle order creation or updating
export const handleOrder = async (profileId, selectedPlan, plan) => {
  try {
    const existingOrders = await getExistingOrder(profileId);
    console.log(existingOrders, "existingOrders", profileId, plan);
    let order;

    // Check if there are existing orders
    if (existingOrders.length > 0) {
      order = existingOrders[0];
      // If the existing order is either 'init' or 'pending', update it using PATCH
      if (order.orderstatus === 'init' || order.orderstatus === 'Pending') {
        await axiosconfig.patch(`/accounts/orders/${order.id}/`, { plan: selectedPlan, net_amount: plan?.price, total_amount: plan?.price });
        console.log(order)
        // await getExistingOrder(profileId);
        return order; // Return the updated order
      }
    }

    // If no existing order or the status is not 'init' or 'pending', create a new order using POST
    const profileData = JSON.parse(localStorage.getItem("profile_data"));
    const newOrder = await axiosconfig.post('/accounts/orders/', { profile: profileId ? profileId : profileData?.profile_id, plan: selectedPlan });
    console.log(newOrder)
    // await getExistingOrder(profileId);
    return newOrder.data;
  } catch (error) {
    console.log(error)
  }
};
export const loadScript = () => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  document.body.appendChild(script);
};

// Initiate payment
export const initiatePayment = async (orderId, setpopupType, setPaymentLoad) => {
  try {
    const response = await loadScript();
    // Step 1: Initiate the payment via API
    const profileData = JSON.parse(localStorage.getItem("profile_data"));
    const responseData = await axiosconfig.post('/accounts/pay/', { order_id: orderId });

    // Step 2: Get payment details
    const paymentData = responseData.data?.payment;
    const description = responseData.data?.order?.order?.plan?.name
    console.log(paymentData)
    // Step 3: Configure payment options
    const options = {
      amount: paymentData.amount,
      currency: "INR",
      name: "TO HCC",
      description: description,
      image: "",
      order_id: paymentData.id,
      handler: function (response) {
        handlePaymentSuccess(response, setpopupType, setPaymentLoad);
      },
      prefill: {
        name: `${profileData.first_name} ${profileData?.last_name}` || "",
        email: "User's email",
        contact: profileData?.mobile_number || "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    console.log(options)
    var rzp1 = new window.Razorpay(options);
    rzp1.open();

    return paymentData; // Optionally return payment details
  } catch (error) {
    console.log('Error initiating payment:', error);

  }
};


// Handle successful payment
export const handlePaymentSuccess = async (paymentResponse, setpopupType, setPaymentLoad) => {
  let bodyData = new FormData();

  // we will send the response we've got from razorpay to the backend to validate the payment
  bodyData.append("response", JSON.stringify(paymentResponse));
  setPaymentLoad && setPaymentLoad(true)
  try {
    const response = await axiosconfig.post('/accounts/payment/success/', bodyData,);
    RefreshLogin()
    setpopupType && setpopupType("success")
    setPaymentLoad && setPaymentLoad(false)
    return response.data.message; // Return success message
  } catch (error) {
    console.log('Error confirming payment success:', error)
    const response = await axiosconfig(`/accounts/api/refresh_login/`)
      if(response.data.is_pro){
        setpopupType && setpopupType("success")
      }else{
        setpopupType && setpopupType("error")
      }
    RefreshLogin()
    setPaymentLoad && setPaymentLoad(false)
  }
};
const RefreshLogin = async () => {
  try {
    const response = await axiosconfig(`/accounts/api/refresh_login/`)
    console.log(response)
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('profile_data', JSON.stringify(response.data.profile_data));
    localStorage.setItem('is_pro', response.data.is_pro ? true : false);
    if (response.data.is_24_craft) {
      localStorage.setItem('is_24_craft', response.data.is_24_craft);
      localStorage.setItem('frames_24', response.data.frames_24);
      localStorage.setItem('hcc_id', response.data.hcc_id);
    }
    window.location.reload();
  }
  catch (error) {
    console.log(error)
  }
}
// Function to confirm plan and handle payment
export const onConfirmPlan = async (profile_id, selectedPlan, setOrder_Id, plan) => {
  if (!selectedPlan) {
    console.log('No plan selected');
    return;
  }

  try {
    const order = await handleOrder(profile_id, selectedPlan, plan);
    console.log(order)
    setOrder_Id && setOrder_Id(order?.id)
  } catch (error) {
    console.log('Error in payment process:', error);

  }
};
