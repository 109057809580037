import React, { useState } from 'react'
import { MdOutlineVideocam, MdOutlineInfo, MdOutlinePhone } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { RiImage2Fill } from "react-icons/ri";
import { TiMicrophoneOutline } from "react-icons/ti";
const ChatContent = () => {
    const messages = [
        { id: 1, text: "learn ipsum learn ipsum learn ipsum", sender: "them", time: "12:20 AM" },
        { id: 2, text: "learn", sender: "them", time: "12:20 AM" },
        { id: 3, text: "learn", sender: "me", time: "12:20 AM" },
        { id: 4, text: "learn ipsum learn ipsum learn ipsum", sender: "me", time: "12:21 AM" },
        { id: 5, text: "learn ipsum learn ipsum learn ipsum ipsum ipsum learn ipsum learn ipsum", sender: "them", time: "12:22 AM" },
        { id: 6, text: "learn ipsum learn ipsum learn ipsum ipsum learn ipsum learn ipsum  ipsum ipsum learn ipsum learn ipsum", sender: "me", time: "12:23 AM" },
    ];
    return (
        <>
            <div className="chat-window">
                <div className="chat-header">
                    <div className="user-info">
                        <div className='chat-image'>
                            <div className='chat-dot'></div>
                            <img src={require('../Post/images/male.jpg')} alt="profile" className="profile-pic" />
                        </div>
                        <div>
                            <div className='header-name'>Dvithik_76</div>
                            <p className="status">Active Now</p>
                        </div>
                    </div>
                    <div className="chat-actions">
                        <MdOutlinePhone size={30} />
                        <MdOutlineVideocam size={32} />
                        <MdOutlineInfo size={32} />
                    </div>
                </div>
                <div className="chat-body">
                    {messages.map((message, index) => (
                        <div key={index} className={`chat-message ${message.sender === "me" ? 'sent' : 'received'}`}>
                            <div className="message-content">
                                {message.sender === "them" && <img src={require('../Post/images/male.jpg')} alt="profile" className="profile-pic-small" />}
                                <div className="message-text">
                                    <p>{message.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                < div className="message-input" >
                    <BsEmojiSmile size={27} />
                    <input type="text" placeholder="Type here..." />
                    <RiImage2Fill size={30} />
                    <TiMicrophoneOutline size={30} />
                </div>
            </div>
        </>
    )
}

export { ChatContent }