import React from "react";
import "./Footer.css";
import { MdMail, MdMailOutline, MdOutlinePhone, MdOutlinePhoneInTalk, MdPinDrop } from "react-icons/md";
import { BiMap } from "react-icons/bi";
const Footer = () => {
    return (
        <>
            <div className="footer-container">
                <footer>
                    <div className="footer-links">
                        <div className="container-fluid">
                            <div className="inner">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="logo-footer">
                                            <a href="#">
                                                <img src={require("./images/logo.png")} alt="Hyderabad Casting Club Logo" />
                                            </a>
                                        </div>
                                        <div className="footer-content">
                                            <p className="description-footer">
                                                Welcome to Hyderabad Casting Club World, your premier platform connecting the diverse talents of the cinema and entertainment industry. We are dedicated to bringing together artists, models, directors, producers, and other professionals across the 24 crafts, offering a unique space to showcase their skills, collaborate, and discover new opportunities.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-information">
                                            <h4 className="foot-our">Our Information</h4>
                                            <div className="address-footer">
                                                <div><BiMap size={18} /></div><span> Srinagar Colony Main Rd, Pratap Nagar, VST Colony, Punjagutta, Hyderabad, Telangana 500082</span>
                                            </div>
                                            <div className="address-footer"><div><MdMailOutline size={18} /></div><span> info@hyderbadcastingclub.com</span></div>
                                            <div className="address-footer"><div><MdOutlinePhone size={18} /></div><span> +91 7799234563</span></div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-information">
                                            <h4 className="foot-our">Quick Links</h4>
                                            <ul className="list-unstyled">
                                                <li><div className="arrow right"></div><a href="/home">Home</a></li>
                                                <li><div className="arrow right"></div><a href="/about-us" target="_blank">About</a></li>
                                                <li><div className="arrow right"></div><a href="/s">Profile</a></li>
                                                <li><div className="arrow right"></div><a href="/auditions">Auditions</a></li>
                                                <li><div className="arrow right"></div><a href="/terms-conditions" target="_blank">Terms and Conditions</a></li>
                                                <li><div className="arrow right"></div><a href="/refund-policy" target="_blank">Refund Policy</a></li>
                                                {/* <li><div className="arrow right"></div><a href="/help">Help</a></li> */}
                                                <li><div className="arrow right"></div><a href="/privacy-policy" target="_blank">Privacy Policies</a></li>
                                                <li><div className="arrow right"></div><a href="/contact-us" target="_blank">Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-copyright">
                            {/* <p className="text-powered mb-0">Copyright &copy; 2023 <a href="https://hyderabadcastingclub.com/">hyderabadcastingclub.com</a>. All Rights Reserved. Developed and Powered by <a href="https://mhninformatics.com/" target="_blank">MHN INFORMATICS PRIVATE LIMITED</a></p> */}
                            <p className="text-powered mb-0">
                                Copyright &copy; 2024 All Rights Reserved. <a href="https://hyderabadcastingclub.com/">Hyderabad Casting Club</a>
                            </p>
                            <div className="d-flex gap-1">
                                <a href="https://www.facebook.com/hydcastingclub?mibextid=ZbWKwL" target="_blank">
                                    <img src={require("./images/facebook.png")} />
                                </a>
                                <a href="https://www.instagram.com/hydcastingclub?igsh=MWN2Nzh5ejRkd3N2NA==" target="_blank">
                                    <img src={require("./images/instagram.png")} width={46} />
                                </a>
                                {/* <img src={require("./images/twitter.png")} /> */}
                                {/* <img src={require("./images/linkedin.png")} /> */}
                                <a href="https://api.whatsapp.com/send?phone=917799234563&text=Hi+I+want+to+know+more+about+Hyderabad+Casting+Club" target="_blank">
                                    <img src={require("./images/whatsapp.png")} />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};
export { Footer };