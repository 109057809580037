import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatList from "../Components/Messages/ChatList";
import { ChatContent } from "../Components/Messages/ChatContent";
const MessagesPage = () => {
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area chat-container">
                    <ChatList />
                    <ChatContent />
                </div>
            </div >
            <Footer />
        </>
    );
}

export { MessagesPage };